import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme/index'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  label: string
  file?: string
  target?: string
  URL?: string
  onClick?: () => void
  className?: string
  noActions?: boolean
  rel?: string
  variant?: Variant
}

export const Button = memo(function Button({
  label = 'Button',
  file,
  target,
  URL,
  onClick,
  className,
  noActions,
  rel,
  variant = 'M',
}: Props) {
  const buttonClassName = `${className ? className : ''}`
  const buttonDOM = <ButtonLabel variant={variant}>{label}</ButtonLabel>
  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  return (
    <>
      {file ? (
        <Anchor
          className={buttonClassName}
          href={file}
          target="_blank"
          rel={rel}
          variant={variant}
        >
          {buttonDOM}
        </Anchor>
      ) : (
        <>
          {!externalURL ? (
            onClick != undefined ? (
              <Anchor
                className={buttonClassName}
                onClick={onClick}
                variant={variant}
              >
                {buttonDOM}
              </Anchor>
            ) : noActions ? (
              <Static className={buttonClassName} variant={variant}>
                {buttonDOM}
              </Static>
            ) : (
              <ButtonItem
                to={URL ? URL : '#'}
                className={buttonClassName}
                variant={variant}
              >
                {buttonDOM}
              </ButtonItem>
            )
          ) : (
            <Anchor
              className={buttonClassName}
              href={URL}
              rel="noopener"
              target={target}
              variant={variant}
            >
              {buttonDOM}
            </Anchor>
          )}
        </>
      )}
    </>
  )
})

const AnimationStyle = css`
  &:hover {
    span {
      &:after {
        animation: lineAnimation 1s forwards;
      }
    }
  }

  @keyframes lineAnimation {
    49% {
      right: 0;
      left: 100%;
    }
    50% {
      left: 0;
      right: 100%;
    }
    100% {
      right: 0;
      left: 0;
    }
  }
`

const SmallStyle = css`
  display: inline-flex;
  align-items: center;
  color: ${theme.colors.variants.neutralDark1};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.0375rem;
  line-height: 0.9375rem;
  margin-top: 2.5rem;
  padding-bottom: 0.25rem;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  white-space: nowrap;

  ${AnimationStyle}
`

const MediumStyle = css`
  cursor: pointer;
  display: inline-block;
  background: ${theme.colors.variants.primaryLight1};
  color: ${theme.colors.variants.neutralLight4};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.6px;
  line-height: 0.9375rem;
  overflow: hidden;
  padding: 1.6875rem 4.6875rem 1.875rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  white-space: nowrap;
  &:hover {
    background: ${theme.colors.getHoverColor(
      theme.colors.variants.primaryLight1,
    )};
  }

  ${AnimationStyle}
`

const LargeStyle = css`
  cursor: pointer;
  display: inline-block;
  background: ${theme.colors.variants.primaryLight1};
  color: ${theme.colors.variants.neutralLight4};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.6px;
  line-height: 0.9375rem;
  overflow: hidden;
  padding: 2.375rem 4.6875rem 2.6875rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  white-space: nowrap;
  &:hover {
    background: ${theme.colors.getHoverColor(
      theme.colors.variants.primaryLight1,
    )};
  }

  ${AnimationStyle}
`

const Anchor = styled.a<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'S':
        return SmallStyle
      case 'M':
        return MediumStyle
      case 'L':
        return LargeStyle
    }
  }}
`

const Static = styled.div<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'S':
        return SmallStyle
      case 'M':
        return MediumStyle
      case 'L':
        return LargeStyle
    }
  }}
`

const ButtonItem = styled(Link)<ContainerProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'S':
        return SmallStyle
      case 'M':
        return MediumStyle
      case 'L':
        return LargeStyle
    }
  }}
`

const ButtonLabel = styled.span<ContainerProps>`
  display: inline-block;
  position: relative;
  z-index: 2;
  &:after {
    content: '';
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    bottom: -0.25rem;
    right: 0;
    left: 0;
    transition: 0.3s ease-in-out;

    ${({ variant }) => {
      switch (variant) {
        case 'S':
          return css`
            background: ${theme.colors.variants.primaryLight1};
          `
      }
    }}
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'S' | 'M' | 'L'
